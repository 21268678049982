import React from 'react'
import { LawyerItemContent, LawyerItemImage, LawyerItemInfoContainer, LawyerItemProfesion, LawyersBackground, LawyersContainer, LawyersContent, LawyersImage, LawyersSection, LawyersSectionContainer, LawyersSubtitle, LawyersTitle, LaywerItemName } from './styles'
import CamiloSuarez from '../../assets/camilo_suarez.jpg'
import BrayanGiraldo from '../../assets/brayan_giraldo.png'

const lawyersItems = [
    {
        name: 'Camilo Suarez',
        image: CamiloSuarez,
        proffesion: `CEO`
    },
    {
        name: 'Brayan Giraldo',
        image: BrayanGiraldo,
        proffesion: 'Abogado Senior'
    }
]

const Lawyers = () => {
    return (
        <LawyersSection id="lawyers">
            <LawyersSectionContainer>
                <LawyersImage src="https://images.pexels.com/photos/8112193/pexels-photo-8112193.jpeg" alt="lawyers" />
                <LawyersContainer>
                    <LawyersTitle>Nuestros abogados</LawyersTitle>
                    <LawyersSubtitle>Tenemos conocimiento técnico y experiencia legal que nos permite navegar con confianza por los desafíos legales y las complejidades de este ecosistema digital.</LawyersSubtitle>
                    <LawyersContent>
                        {
                            lawyersItems.map((item, index) => (
                                <LawyerItemContent key={index}>
                                    <LawyerItemImage src={item.image} alt={item.name} />
                                    <LawyerItemInfoContainer>
                                        <LaywerItemName>{item.name}</LaywerItemName>
                                        <LawyerItemProfesion>{item.proffesion}</LawyerItemProfesion>
                                    </LawyerItemInfoContainer>
                                </LawyerItemContent>
                            ))
                        }
                    </LawyersContent>
                </LawyersContainer>
                <LawyersBackground />
            </LawyersSectionContainer>
        </LawyersSection>
    )
}

export default Lawyers