import React from 'react'
import {
    AboutUsBackground,
    AboutUsButton,
    AboutUsCountContainer,
    AboutUsCountDivider,
    AboutUsCountItem,
    AboutUsCountItemNumber,
    AboutUsCountItemText,
    AboutUsImage,
    AboutUsImageAndInfoContainer,
    AboutUsSection,
    AboutUsServiceContainer,
    AboutUsServicePoint,
    AboutUsServiceSubtitle,
    AboutUsServiceTitle,
    AboutUsSubitle,
    AboutUsTextsContainer,
    AboutUsTitle,
    AboutUseServiceTitleContainer
} from './styles'

const aboutUsItems = [
    {
        text: "Casos",
        number: 200
    },
    {
        text: "Abogados",
        number: 3
    },
    {
        text: "Conexiones",
        number: 54
    }
]

const AboutUs = ({ navigateToContactSection }) => {
    return (
        <AboutUsSection id="about-us">
            <AboutUsImageAndInfoContainer>
                <AboutUsImage src='https://images.pexels.com/photos/5669602/pexels-photo-5669602.jpeg' />
                <AboutUsTextsContainer>

                    <AboutUsTitle>Firma de abogados con más de 5 años de experiencia</AboutUsTitle>
                    <AboutUsSubitle>Somos un equipo de profesionales apasionados y altamente especializados en el ámbito jurídico de estas tecnologías disruptivas.</AboutUsSubitle>

                    <AboutUsCountContainer>
                        {
                            aboutUsItems.map((item, index) => (
                                <AboutUsCountItem key={index}>
                                    <AboutUsCountItemNumber>
                                        {item.number}
                                    </AboutUsCountItemNumber>
                                    <AboutUsCountItemText>
                                        {item.text}
                                    </AboutUsCountItemText>
                                    {index !== aboutUsItems.length - 1 && <AboutUsCountDivider />}
                                </AboutUsCountItem>
                            ))
                        }

                    </AboutUsCountContainer>
                    <AboutUsButton onClick={navigateToContactSection.bind(null, 'contact', 200)}>Escribenos</AboutUsButton>

                </AboutUsTextsContainer>
                <AboutUsBackground />
            </AboutUsImageAndInfoContainer>
            
            <AboutUsServiceContainer>

                <AboutUseServiceTitleContainer>
                    <AboutUsServicePoint />
                    <AboutUsServiceTitle>Nuestros servicios</AboutUsServiceTitle>
                </AboutUseServiceTitleContainer>
                
                <AboutUsServiceSubtitle>Proporcionamos asesoramiento legal integral y soluciones estratégicas para empresas y particulares que operan en el entorno blockchain y criptomonedas.</AboutUsServiceSubtitle>

            </AboutUsServiceContainer>
        </AboutUsSection>
    )
}

export default AboutUs