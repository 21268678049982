import styled from "styled-components";

export default styled.img`
    position: absolute;
    top: 0;
    right: 0;
    width: 40%;
    height: 60vh;
    z-index: -1;
    object-fit: cover;
    filter: grayscale(.7);
    border-radius: 0 0 0 2em;

    ${props => props.theme.media.desktop`
        height: 50vh;
    `}
    ${props => props.theme.media.tablet`
        height: 40vh;
    `}

    ${props => props.theme.media.tablet`
        width: 50%;
    `}
`