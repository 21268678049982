import React from 'react'
import { ContactForm, ContactFormTextArea, ContactFormTextField, ContactInfo, ContactInfoCaption, ContactInfoSubtitle, ContactInfoTitle, ContactSection } from './styles'
import { CustomButton } from "../../components/Button/styles";

const Contact = () => {
    return (
        <ContactSection id="contact">

            <ContactInfo>
                <ContactInfoCaption>¡Contáctanos!</ContactInfoCaption>
                <ContactInfoTitle>Pongamonos en contacto</ContactInfoTitle>
                <ContactInfoSubtitle>
                    ¿Quieres ponerte en contacto con nosotros? Llena el formulario y envíanos un mensaje, O puedes escribirnos por nuestras redes sociales que encuentras al final.
                </ContactInfoSubtitle>

            </ContactInfo>

            <ContactForm action="https://formsubmit.co/requerimientos@svabogados.com" method="POST">
                
                <ContactFormTextField placeholder="Dinos tu nombre" type="text" name="name" required/>
                <ContactFormTextField placeholder="¿Cuál es tu correo electrónico?" type="email" name="email" required/>
                <ContactFormTextArea placeholder="¿Qué mensajes quieres enviarnos?" rows="5" name="message" required/>
                <input type="hidden" name="_subject" value="Mensaje desde suarezvabogados.com"></input>
                <CustomButton type="submit">Enviar</CustomButton>
            </ContactForm>

        </ContactSection>
    )
}

export default Contact
