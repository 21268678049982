import styled from "styled-components";

export const LawyersSection = styled.section`
    position: relative;
    color: white;
`

export const LawyersBackground = styled.div`
    position: absolute;
    width: 90%;
    margin-right: 10%;
    padding-bottom: 10em;
    background-color: #b67f44;
    border-radius: 0 5em 0 0;
    z-index: -1;
    height: 70vh;
    top: 0;
    ${props => props.theme.media.desktop`
        padding-bottom: 1em;
    `}
    ${props => props.theme.media.tablet`
        height: 60vh;
        padding-bottom: 0em;
    `}
    ${props => props.theme.media.mobile`
        width: 100%;
        border-radius: 0;
        height: 85vh;
    `}
`

export const LawyersSectionContainer = styled.div`
    display: grid;
    grid-template-columns: 60% 40%; 
    ${props => props.theme.media.mobile`
        display: block;
    `}
`

export const LawyersContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 5em;
    align-items: end;
    padding-right: 4em;
    ${props => props.theme.media.desktop`
        margin-top: 4em;
    `}
    ${props => props.theme.media.tablet`
        padding-right: 2em;
    `}
    ${props => props.theme.media.mobile`
       align-items: center;
       padding-top: 2em;
       padding-right: 0;
    `}
`

export const LawyersImage = styled.img`
    position: absolute;
    width: 40%;
    height: calc(100vw / 3);
    border-radius: 3em 0 0 0;
    object-fit: cover;
    top: 5em;
    right: 0;
    ${props => props.theme.media.desktop`
       top: 4em;
       height: calc(100vw / 2.5);
    `}
    ${props => props.theme.media.mobile`
        display: none;
    `}
`

export const LawyersTitle = styled.span`
    font-size: 3em;
    font-weight: 600;
    ${props => props.theme.media.desktop`
        font-size: 2em;
    `}
    ${props => props.theme.media.tablet`
       font-size: 1.5em;
    `}
`

export const LawyersSubtitle = styled.span`
    color: #ffffff8c;
    font-size: 1.5em;
    margin-top: 1em;
    margin-left : 6em;
    text-align: end;
    ${props => props.theme.media.desktop`
        font-size: 1.2em;
    `}
    ${props => props.theme.media.tablet`
       font-size: 1em;
    `}
    ${props => props.theme.media.mobile`
        margin-left: 0;
        text-align: center;
    `}
`

export const LawyersContent = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    margin-top: 6em;
    ${props => props.theme.media.desktop`
        margin-top: 2em;
    `}
    ${props => props.theme.media.mobile`
        flex-direction: column;
    `}
`

export const LawyerItemContent = styled.div`
    display: flex;
    flex-direction: column;
    cursor: pointer;
    transition: scale 0.3s;
    align-items: center;
    position: relative;
    &:hover {
        scale: 1.01
    }
`

export const LawyerItemImage = styled.img`
    height: 25em;
    width: 18em;
    object-fit: cover;
    border-radius: 3%;
    ${props => props.theme.media.desktop`
        height: 20em;
        width: 13em;
    `}
    ${props => props.theme.media.tablet`
        height: 18em;
        width: 11em;
    `}
    ${props => props.theme.media.mobile`
        height: 16em;
        width: 12em;
        margin-bottom: 1em;
    `}
`

export const LaywerItemName = styled.span`
    font-size: 1.5em;
    font-weight: bold; 
    ${props => props.theme.media.desktop`
        font-size: 1.2em;
    `}
    ${props => props.theme.media.tablet`
        font-size: 1em;
    `}
`

export const LawyerItemProfesion = styled.span`
    margin-top: 0.5em;
    font-weight: 300;
    white-space: pre-line;
    ${props => props.theme.media.desktop`
        font-size: .8em;
    `}
    ${props => props.theme.media.tablet`
        font-size: .5em;
    `}
`

export const LawyerItemInfoContainer = styled.div`
    position: absolute;
    background-color: rgb(255 255 255 / 80%);
    color: black;
    display: flex;
    flex-direction: column;
    width: 80%;
    bottom: 2em;
    padding: 1em;
    ${props => props.theme.media.desktop`
        width: 70%;
        bottom: 1em;
    `}
    ${props => props.theme.media.mobile`
        width: 30%;
        bottom: 2em;
    `}
`
