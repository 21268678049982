import React from 'react'
import { FooterContent, FooterCopyright, FooterImage, FooterLogoContainer, FooterNetworkContent, FooterNetworkImage, FooterNetworksContainer, FooterNetworksTitle, FooterSection } from './styles'
import Logo from '../../assets/Logo_2.png'
import Twitter from '../../assets/twitter.png'
import Tiktok from '../../assets/tiktok.png'
import Linkedin from '../../assets/linkedin.png'
import Instagram from '../../assets/instagram.png'

const networksList = [
    {
        link: 'https://www.instagram.com/camilosuarezv_/',
        image: Instagram
    },
    {
        link: 'https://twitter.com/camilosuarezven',
        image: Twitter
    },
    {
        link: 'https://www.tiktok.com/@camilosuarezv_',
        image: Tiktok
    },
    {
        link: 'https://www.linkedin.com/company/su%C3%A1rez-venegas-abogados',
        image: Linkedin
    },
]

const Footer = () => {

    const goToNetworkAsoblockchain = (url) => {
        window.open(url, '_blank')
    }

    return (
        <FooterSection>
            <FooterContent>
                <FooterLogoContainer>
                    <FooterImage src={Logo} alt="footer-image" />
                </FooterLogoContainer>
                <FooterNetworksContainer>
                    <FooterNetworksTitle>Redes sociales</FooterNetworksTitle>
                    <FooterNetworkContent>
                        {
                            networksList.map((item, index) => (
                                <FooterNetworkImage key={index} src={item.image} alt="/" onClick={goToNetworkAsoblockchain.bind(null, item.link)} />
                            ))
                        }
                    </FooterNetworkContent>
                </FooterNetworksContainer>
            </FooterContent>
            <FooterCopyright>Copyright © 2023</FooterCopyright>
        </FooterSection>
    )
}

export default Footer