import React from 'react'
import { FounderSection, FounderText, LogoAsoblockchain, LogoSVA } from './styles'
import Logo from '../../assets/Logo_2.png'
import Asoblockchain from '../../assets/asoblockchain.png'

const Founder = () => {
  return (
    <FounderSection>
        <LogoSVA src={Logo} alt='logo-sva' />
        <FounderText>Asociado fundador de</FounderText>
        <LogoAsoblockchain src={Asoblockchain} alt='logo-asoblockchain'/>
    </FounderSection>
  )
}

export default Founder