import styled from "styled-components";

export const FounderSection = styled.section`
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    ${props => props.theme.media.desktop`
        height: 40vh;
        margin-top: 2em;
    `}
    ${props => props.theme.media.mobile`
         flex-direction: column;
         height: auto;
         margin-bottom: 2em;
    `}
`

export const LogoSVA = styled.img`
    width: 20em;
    ${props => props.theme.media.desktop`
        width: 14em;
    `}
    ${props => props.theme.media.desktop`
        width: 10em;
    `}
`

export const FounderText = styled.span`
    font-size: 2em;
    font-weight: 700;
    text-align: center;
    width: 7em;
    margin-right: 1em;
    ${props => props.theme.media.desktop`
        font-size: 1.5em;
    `}

    ${props => props.theme.media.mobile`
        margin-right: 0;
        width: 10em;
    `}

`

export const LogoAsoblockchain = styled.img`
    width: 16em;
    ${props => props.theme.media.desktop`
        width: 12em;
    `}

    ${props => props.theme.media.mobile`
        margin-top: 3em;
        width: 9em;
    `}
`