import styled, { keyframes } from "styled-components";

const animation = keyframes`
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
`

export const Header = styled.header`
    display: flex;
    justify-content: space-between;
    padding: 1em 15em;
    position: fixed;
    z-index: 1000;
    width: 100%;
    box-sizing: border-box;
    background-color: ${props => props.backgroundColor};
    box-shadow: ${props => props.backgroundColor ? '0px 1px 10px #00000059' : 0};
    align-items: center;
    animation-name: ${animation};
    animation-duration: .7s;
    animation-iteration-count: 1;

    ${props => props.theme.media.desktop`
        padding: 1em 10em;
    `}

    ${props => props.theme.media.tablet`
         padding: 1em 2em;
    `}
`

export const Logo = styled.img`
    width: 10em;
    ${props => props.theme.media.tablet`
       width: 8em;
    `}
`

export const NavContainer = styled.nav`
    align-self: center;
    display: flex;
    ${props => props.theme.media.mobile`
         display: none;
    `}
`

export const NavContainerMobile = styled.nav`
    display: ${props => props.show ? 'flex' : 'none' };
    height: 100%;
    width: 100%;
    background-color: white;
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    background: white;
    z-index: 1000;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const MenuHamburger = styled.img`
    width: 2em;
    height: 2em;
    display: none;
    ${props => props.theme.media.tablet`
         display: none;
    `}
    ${props => props.theme.media.desktop`
         display: none;
    `}
    ${props => props.theme.media.desktopXL`
         display: none;
    `}
    ${props => props.theme.media.mobile`
         display: block;
    `}
`

export const NavItem = styled.span`
    color: black;
    margin: 1em;
    cursor: pointer;
    font-weight: 600;
    font-size: 18px;
    position: relative;
    display: block;
    &::before {
        content: "";
        width: 100%;
        height: 3px;
        background-color: #b67f44;
        position: absolute;
        bottom: -13px;
        border-radius: 10px;
        transform: ${props => props.sectionSelected ? 'scale(1, 1)' : 'scale(0, 1)'};
        transition: transform 0.25s ease;
        transform-origin: 0 100%;
    }
    &:hover::before {
        transform: scale(1, 1);
    }
`