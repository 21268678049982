import React, { useState } from 'react'
import { Header, Logo, MenuHamburger, NavContainer, NavContainerMobile, NavItem } from './styles'
import LogoSva from '../../assets/Logo.png'
import Menu from '../../assets/menu.png'

const sectionsList = [
    {
        sectionId: 'hero',
        name: 'Home'
    },
    {
        sectionId: 'about-us',
        offset: 100,
        name: 'Nosotros'
    },
    {
        sectionId: 'lawyers',
        offset: 100,
        name: 'Abogados'
    },
    {
        sectionId: 'contact',
        offset: 200,
        name: 'Contacto'
    },
]

const Navbar = ({ section, navigateToSection }) => {

    const [backgroundColor, setBackgroundColor] = useState(null)
    const [showNavbarMobile, setNavbarMobile] = useState(false)


    window.addEventListener('scroll', function () {
        var scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
        if (scrollPosition > 100) {
            setBackgroundColor("#FFF")
        } else {
            setBackgroundColor(null)
        }
    });


    const navigateToSectionNavbar = (sectionId, offset) => {
        navigateToSection(sectionId, offset)
        setNavbarMobile(false)
    }

    return (
        <Header backgroundColor={backgroundColor}>
            <Logo src={LogoSva} alt='logo sva' />

            <NavContainer>
                {
                    sectionsList.map((item, index) => (
                        <NavItem
                            key={index}
                            sectionSelected={section === item.sectionId}
                            onClick={navigateToSectionNavbar.bind(null, item.sectionId, item.offset)}>
                            {item.name}
                        </NavItem>
                    ))
                }
            </NavContainer>
            
            <MenuHamburger src={Menu} alt="menu" onClick={() => { setNavbarMobile(true) }} />
            <NavContainerMobile show={showNavbarMobile}>
                {
                    sectionsList.map((item, index) => (
                        <NavItem
                            key={index}
                            sectionSelected={section === item.sectionId}
                            onClick={navigateToSectionNavbar.bind(null, item.sectionId, item.offset)}>
                            {item.name}
                        </NavItem>
                    ))
                }
            </NavContainerMobile>
        </Header>
    )
}

export default Navbar