import styled from "styled-components";
import { CustomButton } from "../../components/Button/styles";

export const AboutUsSection = styled.section`
    position: relative;
    margin-top: 5em;
    color: white;
    ${props => props.theme.media.mobile`
       margin-top: 0;
    `}
`
export const AboutUsImageAndInfoContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    ${props => props.theme.media.mobile`
       display: block;
    `}
`

export const AboutUsBackground = styled.div`
    position: absolute;
    width: 90%;
    margin-left: 10%;
    padding-bottom: 10em;
    background-color:  #b67f44;
    border-radius: 5em 0 0 0;
    z-index: -1;
    height: 85%;
    ${props => props.theme.media.tablet`
       padding-bottom: 8em;
    `}
    ${props => props.theme.media.mobile`
       border-radius: 0;
        width: 100%;
        margin-left: 0;
    `}
    ${props => props.theme.media.mobile`
       top: 0
    `}
`

export const AboutUsImage = styled.img`
    width: 90%;
    height: calc(100vw / 3);
    border-radius: 0 3em 0 0;
    object-fit: cover;
    margin-top: 5em;

    ${props => props.theme.media.desktop`
        height: calc(100vw / 2.5);
    `}

    ${props => props.theme.media.tablet`
       font-size: 1em;
    `}

    ${props => props.theme.media.mobile`
       display: none;
    `}
`

export const AboutUsTextsContainer = styled.div`
    padding: 5em;
    padding-left: 0;
    padding-right: 8em;

    ${props => props.theme.media.desktop`
        padding-right: 4em;
    `}

    ${props => props.theme.media.tablet`
        padding-right: 2em;
        padding-bottom: 2em;
    `}

    ${props => props.theme.media.mobile`
        padding: 3em 2em 0 2em;
    `}
`

export const AboutUsTitle = styled.span`
    display: inline-block;
    color: white;
    font-size: 3em;
    font-weight: 600;

    ${props => props.theme.media.desktop`
        font-size: 2em;
    `}

    ${props => props.theme.media.tablet`
       font-size: 1.5em;
    `}

    ${props => props.theme.media.mobile`
        font-size: 2em;
        text-align: center;
    `}
`

export const AboutUsSubitle = styled.span`
    display: inline-block;
    color: #ffffff8c;
    font-size: 1.5em;
    margin-top: 1em;
    margin-right: 6em;

    ${props => props.theme.media.desktop`
        font-size: 1.2em;
    `}

    ${props => props.theme.media.tablet`
       font-size: 1em;
    `}

    ${props => props.theme.media.mobile`
        margin-top: 1em;
        margin-right: 0;
        text-align: center;
    `}
`

export const AboutUsCountContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    position: relative;
    padding: 10px;
    border: 2px solid white;
    border-radius: 8px;
    width: fit-content;
    margin-top: 2em;
    padding: 1em 2em;
    ${props => props.theme.media.tablet`
       margin: 2em auto;
    `}
`

export const AboutUsCountItem = styled.div`
    color: white;
    text-align: center;
    position: relative;
    padding: 0 2em;
    ${props => props.theme.media.mobile`
      padding: 0 1em;
    `}
`

export const AboutUsCountItemText = styled.div`
    font-size: 14px;
    ${props => props.theme.media.desktop`
        font-size: 12px;
    `}

`

export const AboutUsCountDivider = styled.div`
    position: absolute;
    height: 100%;
    border: .5px solid white;
    right: 0;
    top: 0;
`

export const AboutUsCountItemNumber = styled.div`
    font-size: 2em;
    font-weight: 600;
    text-align: center;
    ${props => props.theme.media.desktop`
        font-size: 1.5em;
    `}
    ${props => props.theme.media.tablet`
       font-size: 1em;
    `}
`

export const AboutUsButton = styled(CustomButton)`
    background-color: white;
    color: #b67f44;
    ${props => props.theme.media.mobile`
        margin: 0 auto;
        display: block;
    `}
`

export const AboutUsServiceContainer = styled.div`
    width: 60%;
    margin-left: 15%;
    ${props => props.theme.media.mobile`
        margin-top: 2em;
        margin-left: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
    `}
`

export const AboutUseServiceTitleContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    ${props => props.theme.media.mobile`
        justify-content: center;
    `}
`

export const AboutUsServicePoint = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: white;
    ${props => props.theme.media.mobile`
        display: none;
    `}
`

export const AboutUsServiceTitle = styled.span`
    font-size: 1.5em;
    margin-left: 0.5em;
    font-weight: bold;
    ${props => props.theme.media.desktop`
        font-size: 1.2em;
    `}
    ${props => props.theme.media.tablet`
        font-size: 1em;
    `}
`

export const AboutUsServiceSubtitle = styled.span`
    font-size: 2em;
    color: #ffffffcf;
    ${props => props.theme.media.desktop`
        font-size: 1.5em;
    `}
    ${props => props.theme.media.tablet`
        font-size: 1.2em;
    `}
    ${props => props.theme.media.mobile`
        text-align: center;
        margin: 0 2em;
    `}

`