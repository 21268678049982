import styled from "styled-components";

export const MainContent = styled.main`
    display: grid;
    grid-template-columns: 50% 50%;
    ${props => props.theme.media.mobile`
       display: flex;
       flex-direction: column-reverse;
    `}
`;

export const TitlesContainer = styled.div`
    margin-top: 6em;
    margin-left: 15em;
    display: flex;
    flex-direction: column;

    ${props => props.theme.media.desktop`
       margin-left: 10em;
       margin-top: 3em;
    `}

    ${props => props.theme.media.tablet`
        margin-left: 3em;
    `}

    ${props => props.theme.media.mobile`
        margin-left: 0em;
        align-items: center;
        margin-top: 1em;
    `}
`

export const TitleHome = styled.span`
    font-size: 5rem;
    text-align: start;
    font-weight: 800;
    ${props => props.theme.media.desktopXL`
       font-size: 3.5rem;
    `}

    ${props => props.theme.media.desktop`
       font-size: 3.5rem;
    `}

    ${props => props.theme.media.tablet`
       font-size: 2.5rem;
    `}

    ${props => props.theme.media.mobile`
        text-align: center;
    `}
`

export const SubtitleHome = styled.span`
    font-size: 1.2em;
    text-align: start;
    font-weight: 100;
    margin-top: 1em;
    ${props => props.theme.media.tablet`
       font-size: 1rem;
    `}

    ${props => props.theme.media.mobile`
        text-align: center;
    `}
`

export const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 3em;
    ${props => props.theme.media.mobile`
        margin-top: 1em;
   `}
`

export const ImageCamilo = styled.img`
  width: 50%; 
  object-fit: cover;
`;

export const DividerLine = styled.div`
  height: 3px;
  margin: 0% 11em;
  background-color: rgb(187 194 197 / 35%);
  border-radius: 10px;
  ${props => props.theme.media.tablet`
        height: 2px;
        margin: 0% 2em;
    `}
  ${props => props.theme.media.mobile`
        display: none;
   `}
`

export const DividerLineMobile = styled(DividerLine)`
  ${props => props.theme.media.mobile`
        display: block;
   `}
`

export const ImagesClientTitle = styled.span`
    font-size: 1.5em;
    font-weight: bold;
    color: #b67f44;
    ${props => props.theme.media.desktop`
        font-size: 1.2em;
    `}
    ${props => props.theme.media.tablet`
       font-size: 1em;
    `}
    ${props => props.theme.media.mobile`
        display: none;
    `}
`

export const ImagesClientRow = styled.div`
    margin: 0% 15em 0 15em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1em;
    ${props => props.theme.media.desktop`
        margin: 1em 5em 0px;
    `}
    ${props => props.theme.media.tablet`
        margin: 1em 2em 0px;
    `}
    ${props => props.theme.media.mobile`
        margin: 3em 0em;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        justify-items: center;
        grid-gap: 2em;
    `}
`

export const ImagesClientContainer = styled.div`
    margin-top: 1.5em;
    ${props => props.theme.media.desktop`
       margin-top: 1em;
    `}
`

export const ImageClient = styled.img`
    height: 7em;
    filter: grayscale(1);
    opacity: 0.5;
    cursor: pointer;
    transition: scale 0.3s;
    &:hover {
        scale: 1.1
    }

    ${props => props.theme.media.desktop`
        height: 5em;
    `}
`

