import styled from "styled-components";

export const CustomButton = styled.button`
    width: fit-content;
    height: 48px;
    padding: 0 1em;
    margin-top: 2em;
    border: 0;
    text-transform: uppercase;
    font-family: 'Lato';
    background-color: #b67f44;
    color: white;
    letter-spacing: 1px;
    border-radius: 5px;
    box-shadow: 1px 6px 20px 0px #9e6a32;
    font-size: 12px;
    cursor: pointer;
    transition: scale 0.3s;
    &:active {
      scale: 0.9;
    }
`