import { css } from 'styled-components';

export const theme = {
    media: {
      desktopXL: (...args) => css`
      @media screen and (max-width: 1330px) {
        ${css(...args)}
      }
      `,
      desktop: (...args) => css`
        @media screen and (max-width: 1024px) {
          ${css(...args)}
        }
      `,
      tablet: (...args) => css`
        @media screen and (max-width: 1023px) {
          ${css(...args)}
        }
      `,
      mobile: (...args) => css`
        @media screen and (max-width: 767px) {
          ${css(...args)}
        }
      `,
    },
  };