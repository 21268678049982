import { useState } from 'react';
import './App.css';
import Hero from './pages/hero';
import Navbar from './components/Navbar';
import ImageHero from './components/ImageHero/styles';
import AboutUs from './pages/aboutus';
import Founder from './pages/founder';
import Lawyers from './pages/lawyers';
import Contact from './pages/contact';
import Footer from './pages/footer';
import { navigateIntoSections } from './utils/navigationIntoSections';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme/config';

const App = () => {

  const [section, setSection] = useState('hero')

  const navigateToSection = (sectionId, offset) => {
    navigateIntoSections(sectionId, offset)
    setSection(sectionId)
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="App">

        <Navbar section={section} navigateToSection={navigateToSection} />
        <Hero navigateToContactSection={navigateToSection} />
        <AboutUs navigateToContactSection={navigateToSection} />
        <Founder />
        <Lawyers />
        <Contact />
        <Footer />
        <ImageHero src='https://images.pexels.com/photos/1106476/pexels-photo-1106476.jpeg' />
      </div>
    </ThemeProvider>
  );
}

export default App;
