import styled from "styled-components";

export const ContactSection = styled.section`
    display: grid;
    grid-template-columns: 50% 50%;
    height: 50vh;
    margin-top: 10em;
    padding: 0px 15%;
    ${props => props.theme.media.tablet`
        margin-top: 5em;
    `}

    ${props => props.theme.media.mobile`
        display: flex;
        flex-direction: column;
        padding: 2em 2em;
        margin-top: 3em;
        height: auto;
    `}
`

export const ContactInfo = styled.div`
    display: flex;
    flex-direction: column;
`

export const ContactForm = styled.form`
   ${props => props.theme.media.mobile`
        text-align: center;
    `}
`

export const ContactInfoTitle = styled.span`
    font-size: 3em;
    font-weight: 700;
    margin-top: 0.2em;
    ${props => props.theme.media.desktop`
        font-size: 2.5em;
    `}
`

export const ContactInfoCaption = styled.span`
    color: #b67f44;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-weight: 600;
`

export const ContactInfoSubtitle = styled.span`
    font-size: 1.5em;
    font-weight: 300;
    margin-top: 1em;
    margin-right: 3em;
    ${props => props.theme.media.desktop`
        font-size: 1.1em;
    `}
`

export const ContactFormTextField = styled.input`
    height: 48px;
    border: 1px solid #b67f44;
    border-radius: .7em;
    width: 100%;
    padding: 0 1em;
    font-size: 1em;
    margin-top: 1em;
    box-sizing: border-box;
`

export const ContactFormTextArea = styled.textarea`
    border: 1px solid #b67f44;
    border-radius: .7em;
    width: 100%;
    padding: 1em;
    font-size: 1em;
    font-family: 'Lato';
    margin-top: 1em;
    resize: none;
    box-sizing: border-box;
`