import styled from "styled-components";

export const FooterSection = styled.section`
    background-color: #1c1c1c;
    padding: 2em;
    color: white;
`

export const FooterContent = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    ${props => props.theme.media.mobile`
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    `}
`

export const FooterNetworksContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: auto;
    margin-left: 2em;
    ${props => props.theme.media.mobile`
        width: auto;
        justify-content: center;
        align-items: center;
        margin: 0;
    `}
`

export const FooterNetworksTitle = styled.span`
    font-size: 1.3em;
`

export const FooterNetworkContent = styled.div`
    margin-top: 1em;
`


export const FooterNetworkImage = styled.img`
    width: 2em;
    margin-right: 1em;
    cursor: pointer;
`

export const FooterImage = styled.img`
    height: 20em;
`

export const FooterCopyright = styled.span`
    color: white;
    margin: 0 auto;
    display: block;
    text-align: center;
    margin-top: 2em;
    ${props => props.theme.media.mobile`
         margin-top: 0em;
    `}
`

export const FooterLogoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;
    border-right: 1px solid #ffffff12;
    ${props => props.theme.media.mobile`
        border: 0;
    `}
`