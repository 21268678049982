import React from 'react'
import {
    MainContent,
    TitleHome,
    ImageContainer,
    ImageCamilo,
    DividerLine,
    TitlesContainer,
    SubtitleHome,
    ImageClient,
    ImagesClientContainer,
    ImagesClientTitle,
    ImagesClientRow
} from './styles'
import Camilo from '../../assets/Camilo.png';
import { CustomButton } from '../../components/Button/styles';
import Bavaria from '../../assets/bavaria.png';
import BeerMoney from '../../assets/beer_money.png';
import Mind from '../../assets/mind.png';
import Paxful from '../../assets/paxful.png';
import Trokera from '../../assets/trokera.png';
import CryptoLatinFest from '../../assets/cripto_latin_fest.png';
import { motion } from "framer-motion"


const clientsImages = [
    {
        name: 'bavaria',
        link: 'https://www.bavaria.co/',
        image: Bavaria
    },
    {
        name: 'paxful',
        link: 'https://paxful.com/es',
        image: Paxful
    },
    {
        name: 'beer-money',
        link: 'https://beer-money.io/',
        image: BeerMoney
    },
    {
        name: 'mind',
        link: 'https://mindworld.io/',
        image: Mind
    },
    {
        name: 'trokera',
        link: 'https://www.trokera.com/',
        image: Trokera
    },
    {
        name: 'cryptolatinfest',
        link: 'https://criptolatinfest.com/',
        image: CryptoLatinFest
    }
]

const Hero = ({ navigateToContactSection }) => {

    const goToClient = (url) => {
        window.open(url, '_blank')
    }

    return (
        <section id='hero' style={{ textAlign: 'center', paddingTop: '6em' }}>
            <MainContent>
                <motion.div
                    initial={{ opacity: 0, translateX: -1000 }}
                    animate={{ opacity: 1, translateX: 0 }}
                    transition={{ duration: 0.5 }}>
                    <TitlesContainer>
                        <TitleHome>Suarez Venegas Abogados</TitleHome>
                        <SubtitleHome>Defendiendo sus derechos e intereses en la era de la tecnología blockchain.</SubtitleHome>
                        <CustomButton onClick={navigateToContactSection.bind(null, 'contact', 200)}>Contáctanos</CustomButton>
                    </TitlesContainer>
                </motion.div>

                <motion.div
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: .7 }}>
                    <ImageContainer>
                        <ImageCamilo src={Camilo} alt="Camilo Suarez Venegas" />
                    </ImageContainer>
                </motion.div>

            </MainContent>
            <motion.div initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 1 }}>
                <DividerLine />
            </motion.div>
            <motion.div
                initial={{ translateY: 1000, opacity: 0 }}
                animate={{ translateY: 0, opacity: 1 }}
                transition={{ duration: .8 }}>
                <ImagesClientContainer>
                    <ImagesClientTitle>Clientes</ImagesClientTitle>
                    <ImagesClientRow>
                        {
                            clientsImages.map((item, index) => (
                                <ImageClient
                                    style={{ height: item.name === 'paxful' && '2em' }}
                                    key={index}
                                    src={item.image}
                                    alt={item.name}
                                    onClick={goToClient.bind(null, item.link)} />
                            ))
                        }
                    </ImagesClientRow>
                </ImagesClientContainer>
            </motion.div>
        </section>

    )
}

export default Hero